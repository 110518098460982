<template>
  <div>
    <div class="pagePadding">
      <div>
        <span class="pageBtn finger btnReset" @click="back">返回</span>
      </div>
      <div class="titleBox">
        <span class="noLeft">供应商：{{ info.supplier_name }}</span>
        <span>发票单号：{{ info.invoice_no }}</span>
        <span>发票代码：{{ info.invoice_code }}</span>
        <span>发票号码：{{ info.invoice_number }}</span>
        <span>发票金额：{{ info.invoice_amount }}</span>
        <span>开票时间：{{ info.invoice_time }}</span>
        <span>备注：{{ info.note }}</span>
      </div>
      <div class="table">
        <div class="item borderBtm">
          <div class="body">
            <div class="look">
              查看发票：
            </div>
            <div class="uploading">
              <div class="chaoguo">
                <span v-for="(item, index) in info.invoice_info" :key="index">
                  <a @click="openFile(item.invoice_url)">{{ item.invoice_name }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="table">
        <Table :productList="listColumns" :productData="listData" :total="total" :pages="pages" @change-page="changePage" :loading="isLoad"> </Table>
        <div class="jineBox">
          <span class="jinelit">本次开票数量：{{ totalnowAmount }}</span>
          <span>本次开票金额：{{ info.invoice_amount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
import FormUpload from '../../components/formUpload.vue'
export default {
  name: 'lookListTable',
  data() {
    return {
      info: {},
      savePageContent: [],
      readonly: false,
      debitNote: [],
      totalnowAmount: 0,
      totalnowAmountNum: 0,
      totalnowQuantity: 0,
      suibianba: '',
      contentBoxObj: {
        invoice_time: '',
        todayTime: '',
        supplier_id: '',
        invoice_info: [],
        invoice_amount: '',
        invoice_lines: [],
        invoice_code: '',
        invoice_number: '',
      },
      selectionTableStatus: false,
      selectionTableList: [],
      productSelect: [],
      SKUSelect: [],
      productList: [],
      isLoad: true,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '产品编号',
          key: 'product_model_code',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '规格型号',
          key: 'model_name',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '采购单价',
          key: 'unit_price',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = ''
            if (param.row.unit_price == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.unit_price, true)
            }
            return h('span', str)
          },
        },
        {
          title: '数量',
          key: 'invoice_quantity',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '单位',
          key: 'unit',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '金额',
          key: 'amount',
          align: 'center',
          minWidth: 100,
          render: (h, param) => {
            let str = ''
            if (param.row.amount == null) {
              str = ''
            } else {
              str = this.$utils.formatMoney(param.row.amount, true)
            }
            return h('span', str)
          },
        },
        {
          title: '批号',
          key: 'batch_number',
          align: 'center',
          minWidth: 100,
        },
        {
          title: '有效期',
          key: 'valid_period',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            return h('div', [h('span', {}, param.row.valid_period ? this.$moment(param.row.valid_period * 1000).format('YYYY-MM-DD') : '')])
          },
        },
        {
          title: '采购单号',
          key: 'po_order_number',
          align: 'center',
          minWidth: 120,
        },
      ],
      // 表单拉下选项
      thirdList: [],
      gongyingshangList: [],
      kehuList: [],
      // 查询参数
      queryFrom: {
        product_model_code: '',
        product_name: '',
        specification_id_str: '',
        po_order_number: '',
      },
      // 页码
      pages: {
        page: 1,
        rows: 10,
      },
      // 总条数
      total: 1,
      // 合计金额
      totleMoney: 0,
      modelList: [],
      todayTime: '',
    }
  },
  components: {
    Table,
    FormUpload,
  },
  methods: {
    getKey(key, file_name, url) {
      this.debitNote.push({
        invoice_key: key,
        // invoice_url: url,
        invoice_name: file_name,
      })
    },
    // 图片查看
    openFile(url) {
      window.open(url)
    },
    // 删除图片
    deleteImage(index) {
      this.debitNote.splice(index, 1)
    },
    changeQuan(e, index, num) {
      if (Number(e.target.value) > num) {
        this.listData[index].nowAmount = num
        this.$Message.warning(`开票数量不可大于可开票数量`)
        return
      }
      this.listData[index].nowAmount = Number(e.target.value)
    },
    sumTotal() {
      let total = 0
      let money = 0
      this.selectionTableList.forEach(item => {
        total += item.nowAmount
        money += item.amount
      })
      this.totalnowAmount = total
      this.totalnowAmountNum = money
      this.totalnowQuantity = this.$utils.formatMoney(money, true)
    },
    back() {
      window.history.go(-1)
    },
    // 查询
    query() {
      this.isLoad = true
      this.pages.page = 1
      this.getInvoiceManage()
    },
    // 获取发票列表
    async getInvoiceManage() {
      let result = {}
      //   Object.assign(data, this.pages, this.queryFrom)
      result = await this.$http.get(this.$api.supplierInvoiceDetailLook, { invoice_id: this.$route.query.invoice_id }, true)
      this.listData = result.data.detail
      this.info = result.data
      this.info.invoice_amount = this.$utils.formatMoney(this.info.invoice_amount, true)
      this.info.invoice_time = this.$moment(this.info.invoice_time * 1000).format('YYYY-MM-DD')
      let total = 0
      //   let money = 0
      this.listData.forEach(item => {
        total += item.invoice_quantity
        //  money += item.invoice_amount
      })
      this.totalnowAmount = total
      //   this.totalnowQuantity = money
      this.isLoad = false
    },
    // 分页改变
    changePage(e) {
      this.pages.page = e
      this.getInvoiceManage()
    },
  },
  mounted() {
    if (this.$route.query.supplier_id) {
      this.queryFrom.supplier_id = this.$route.query.supplier_id
    }
    this.query()
  },
}
</script>

<style lang="less" scoped>
.margin10 {
  margin-left: 10px;
}
.titleBox {
  color: #525b6d;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 600;
  span {
    margin-left: 50px;
  }
  .noLeft {
    margin-left: 0;
  }
}
.btnClass {
  span {
    float: left;
    margin-bottom: 10px;
  }
}
.contentBox {
  .contentCbox {
    min-height: 50px;
    margin-top: -2px;
    display: flex;
  }
  .left {
    background-color: #f8f8f9;
    width: 130px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
  .right {
    width: 390px;
    border: 1px solid #ccc;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    border-left: none;
    border-bottom: none;
  }
}
.foot {
  margin-top: 20px;
  text-align: right;
}
.table {
  position: relative;
}
.borderBom {
  border-bottom: 1px solid #ccc !important;
}
.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #525b6d;
  .newItem {
    display: flex;
    border: 1px solid #e8eaec;
    border-bottom: 0;
    .title {
      min-height: 50px;
      width: 200px;
      padding-left: 30px;
      background: #f8f8f9;
      border-right: 1px solid #e8eaec;
      display: flex;
      align-items: center;
    }
    .body {
      padding-left: 20px;
      display: flex;
      align-items: center;
      margin: 7px 0;
      .width1000 {
        width: 1000px;
      }
      .uploading {
        min-height: 36px;
        line-height: 36px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        padding-left: 7px;
        display: flex;
        flex-wrap: wrap;
        .tip {
          font-size: 14px;
          padding: 0 0 0 4px;
          color: #c5c8ce;
          cursor: default;
          user-select: none;
        }
        .itemSpan {
          border: 1px solid #dddddd;
          border-radius: 13px;
          padding: 1px 10px;
          margin: 3px 10px 3px 0;
          // cursor: pointer;
          span {
            border: 0;
            text-decoration: none;
            color: #333333;
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          i {
            font-style: normal;
            padding-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .item {
    width: 100%;
    display: flex;
    height: 50px;
    line-height: 50px;
    .body {
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      align-items: center;
      .look {
        width: 5%;
        text-align: left;
        color: #525b6d;
        font-size: 16px;
        font-weight: 600;
      }
      .uploading {
        text-align: left;
        padding-left: 4px;
        width: 100%;
        height: 50px;
        line-height: 36px;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        .tip {
          font-size: 14px;
          color: #c5c8ce;
          cursor: default;
          user-select: none;
        }
        .upload {
          position: absolute;
          bottom: 0;
          right: 0;
          z-index: 999;
          width: 52px;
          height: 100%;
          background-color: #f4f4f4;
          border-left: 1px solid #e3e3e3;
          float: right;
          text-align: center;
          font-size: 16px;
        }
        span {
          border: 1px solid #c9d5ff;
          border-radius: 13px;
          padding: 7px 17px;
          margin-right: 10px;
          cursor: pointer;
          background-color: #eef1ff;
          a {
            text-decoration: none;
            color: #426cfc;
            max-width: 80px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          i {
            font-style: normal;
            padding-left: 10px;
          }
        }
      }

      .width300 {
        width: 300px;
      }

      .width1000 {
        width: 1000px !important;
      }
    }
  }
  p {
    font-size: 18px;
    font-weight: bold;
    color: #525b6d;
    margin: 20px 0;
  }
}
.chaoguo {
  overflow: hidden;
  height: 50px;
  padding-top: 6px;
}
.jineBox {
  position: absolute;
  bottom: 5px;
  right: 0px;
  z-index: 999;
  font-weight: 600;
  font-size: 16px;
}
.jinelit {
  margin-right: 100px;
}
</style>
